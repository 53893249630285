@import url('https://fonts.googleapis.com/css?family=Black+And+White+Picture');
@import url('https://fonts.googleapis.com/css?family=Black+Han+Sans');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

span {cursor:pointer; }
.plusMinus{
  margin:20px;
  display: flex;
  justify-content: center;
}

.minus, .plus{
  width: 70px;
  background:#f2f2f2;
  border-radius:4px;
  border:1px solid #ddd;
}

input{
  width: 100px;
  text-align: center;
  font-size: 26px;
  border:1px solid #ddd;
  border-radius:4px;
}

.model-container {
height: 700px;
}

.quantitySelector {
  margin-bottom: 20px;
  height: 100px;
  font-size: 50px;
  width: 90px;
  text-align: center;
}

.mintContainer {
  display: flex;
  flex-direction: column;
  font-size: 60px;
}

.modelContainer {
  width: 300px;
}

.navigation {
  padding: 40px;
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid black;
}

.links {
  display: flex;
  justify-content: space-between;
}

.homepage {
  margin-right: 30px;
}

.aboveFold {
  text-align: center;
}

.title {
  font-family: "Black and White Picture";
  font-weight: 500;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.mainTitle {
  padding-top: 80px;
  font-size: 350px;
  text-transform: uppercase;
  height:450px;
}

.secondaryTitle {
  font-size: 75px;
  padding-bottom: 10px;
}

.explainerBlock {
  display: flex;
  justify-content: center;
  font-family: 'Black Han Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 62px;
  padding: 100px 200px 50px;
}

.explainerCellDescription {
  padding-left: 50px;
  width: 661px;
}


.mintButton {
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 522px;
    height: 152px;
    font-size: 70px;
    font-family: "Black Han Sans";
    text-transform: uppercase;
    background: #084607;
    border: 1px solid #084607;
    box-sizing: border-box;
    border-radius: 100px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu {
  height: 40px;
  background-color: brown;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
